.img-responsive {
    /* max-width: 80%; */
    max-height: 30vh; 
    height: auto; 
    object-fit: contain; 
    display: block; 
    /* margin: 0 auto;  */
}


.image-and-title-container {
    position: relative;
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
  }
.swiper-wrapper {
    width: 100vw;
}

.swiper-slide {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
}

.image-and-title-container p {
    margin-bottom: 0;
}



.image-and-title-container .img-thumbnail {
    border-radius: 0;
    border: 0;
    max-height: 80%;
    max-width: 95%;
    background-color: rgba(0,0,0,0);
    margin-left: auto;
    /* width: auto; */
}

.image-container-mobile p {
    margin-left: 0;
    margin-right: 0;
}