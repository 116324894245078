/* Parent container */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header-acct {
    height: 15vh;
}

.header-content-acct {
    flex-grow: 1;
    height: 10vh;
}
.centered-container {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 20px;
    
}

.my-account-form {
    color: white;
    text-align: left; 
    width: 50%; 
    transform: translateX(-30%); 
    padding: 20px;
    border: solid;
    border-radius: 15px;
}

.my-account-form h2 {
    margin-left: 0;
    margin-bottom: 20px;
}

.edit-mode .field-group {
    display: flex;
    justify-content: flex-end;
}

.field-group {
    margin-bottom: 20px; 
    
}

/* Labels and paragraphs */
.my-account-form label, .my-account-form p {
    display: flex; 
    font-size: 24px;
    margin-right: 10px; 
    margin-left: 0;
    flex-grow: 1;
}

.my-account-form label {
    font-weight: bold;
    width: 30%;
}

/* Input and textarea styles */
.my-account-form input[type="text"],
.my-account-form input[type="email"],
.my-account-form textarea {
    width: 35%; 
    background: transparent;
    border: none;
    border-bottom: 2px solid white;
    color: white;
    font-size: 20px;
    border-radius: 10px;
    margin-left: 25px;
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
}

.my-account-form textarea {
    height: 120px;
}

.my-account-form input:focus,
.my-account-form textarea:focus {
    outline: none;
    border-color: #007bff; 
}

/* Button styles */
.account-action-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.account-action-buttons button {
    background-color: #31a957; 
    color: white;
    border: none;
    padding: 8px 15px; 
    font-family: 'Chakra Petch', sans-serif;
    font-size: 14px; 
    font-weight: bold;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.account-action-buttons button:hover {
    background-color: #278e3b;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Header nav styles */
.header-nav-acct a {
    text-decoration: none; 
    color: #fff;
}

.header-nav-acct a:hover {
    text-decoration: underline; 
}

.header-nav-acct ul {
    list-style: none; 
    padding: 0; 
    display: flex; 
    margin-top: 1.5%;
    margin-left: 3%; 
}

.header-nav-acct li {
    margin-right: 31px;
    font-size: 20px;
    font-family: 'Chakra Petch', sans-serif;
}

/* Edit mode - hides labels and shows inputs */
.edit-mode label {
    display: none;
}

.edit-mode .field-group p {
    display: flex; 

    flex-grow: 1;
}

.edit-mode .field-group input[type="text"],
.edit-mode .field-group input[type="email"],
.edit-mode .field-group textarea {
    display: inline-block;
    margin-top: 0; 
    font-size: 12px;
}

.edit-mode .field-group {
    display: block; 
}

.edit-mode .field-group input[type="text"],
.edit-mode .field-group input[type="email"],
.edit-mode .field-group textarea {
    width: 100%; 
}

.edit-mode .field-group label,
.edit-mode .field-group input[type="text"],
.edit-mode .field-group input[type="email"],
.edit-mode .field-group textarea {
    margin-right: 10px;
}

.about-text-overlay h2 {
    color: white;
    font-size: 4vw;
    margin-left: 0;
    width: 40vw;
    margin-bottom: 2vh;
}

.about-text-overlay p {
    color: white;
    font-size: 1.3vw;
    margin-left: 0;
}

.color-overlay-myacct {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
        to right, 
        rgb(27, 32, 54) 0%,
        rgb(27, 32, 54) 35%,
        rgba(0,0,0,0) 65%);
}