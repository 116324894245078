/* createJournal.css */

.create-blog-post {
  flex: 1; 
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 0 5px 5px 0; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: initial;
  height: 100%;
}

.create-blog-post h2 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-left: 25px;
  text-align: left;
  
}

.form-group {
  margin-bottom: 10px;
}

.form-group textarea {
  height: 100px;
}

.custom-select {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: .5rem 1rem;
  width: 90%;
  margin-left: 25px;
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Chakra Petch', sans-serif;
}


label {
  margin-left: 25px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: 'Chakra Petch', sans-serif;
}

input[type="text"],
input[type="date"],
textarea {
  margin-left: 25px;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Chakra Petch', sans-serif;
}

button[type="save"] {
  margin-left: 25px;
  background-color: #007bff;
  color: #fff;
  width: 24%;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

button[type="save"]:hover {
  background-color: #0056b3;
}

button[type="submit"] {
  margin-left: 25px;
  background-color: #31a957;
  color: #fff;
  width: 24%;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}
  
button[type="submit"]:hover {
  background-color: #216c39;
}
  

.create-journal-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto; /* If you want the whole container to scroll */
  height: calc(100vh - 40px); /* Adjust for padding */
  max-height: calc(100vh - 40px);
}

.create-blog-post, .map-container {
  flex: 1; /* Each child takes up half the space */
  overflow-y: auto; /* Add scroll inside each block if needed */
}

.create-journal-container h2 {
  font-size: 40px;
}

.map-container {
  height: 90%; /* Full height of the container */
  position: relative; /* Adjust position */
}

#mapbox {
  width: 70%; /* Adjust width as needed */
  height: 70%; /* Adjust height as needed */
  background-color: #eaeaea; /* Placeholder color, remove this when you integrate Mapbox */
  border: 1px solid #ccc;
  border-radius: 5px;
}

.map-sub-container {
  height: 75%;
}


.expand-map-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  width: 30px; /* Size of expand button */
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
}



.feature-naming-modal {
  position: absolute;
  bottom: 25px;         
  right: 25px;
  background-color: white; 
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.title-input {
  margin-left: 25px;
  width: 90%;
  border: none;
  outline: none;
  padding: 10px 0;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 24px;
  background-color: transparent; /* Make the background transparent */
  color: #333; /* Text color similar to normal text */
}

.title-input::placeholder {
  color: #aaa; /* Light grey color for placeholder text */
}

.title-input:focus {
  border-bottom: 2px solid #007bff; /* Blue underline on focus */
}
.create-journal-container {
  padding: 0 15px; /* Adjust as needed */
}

@media (min-width: 768px) {
  .create-journal-container {
    padding: 0 100px;
    height: calc(100vh - 200px); /* Adjust for padding */
    max-height: calc(100vh - 200px);
  }
}

.info-icon {
  display: inline-block;
  margin-left: 5px;
  cursor: help;
  border: 1px solid #ccc; /* Outline color */
  border-radius: 90%; /* Circular border */
  padding: 4px; /* Space inside border */
  font-size: 12px; /* Adjust size as needed */
  text-align: center;
}

.info-icon:hover::after {
  content: attr(data-tooltip);
  position: relative;
  padding: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  top: 50%; /* Aligns center vertically */
  left: 20%; /* Positions to the right of the icon */
  transform: translate(0, 0); /* Adjust vertical alignment */
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.form-border {
  border: 1px solid #ccc; /* Adjust color as needed */
  padding: 20px;
  margin-top: 20px;
}

.create-blog-post, .welcome-message-form {
  margin-bottom: 20px;
}

.create-journal-header h2 {
  margin-top: 2%;
  margin-left: 25px;
  font-size: 3rem;
  margin-bottom: 1%;
}

.create-journal-header  {
  margin-bottom: 2%;
}

.journal-submit {
  margin-top: 2%;
  font-family: 'Chakra Petch', sans-serif;
}

/* Parent container of the input field and the dropdown */
.location-search-container {
  position: relative;
}

/* Dropdown container */
.search-dropdown {
  position: absolute;
  top: 100%; /* Positioned right below the input field */
  left: 0;
  margin-left: 25px;
  width: 90%; /* Assuming you want it as wide as the input field */
  z-index: 1000; /* Ensures it renders above other elements */
  background: white; /* Or any color that fits your design */
  border: 1px solid #ccc; /* Optional, for better visibility */
  max-height: 200px; /* Maximum height before scrolling */
  overflow-y: auto; /* Enable scrolling within the dropdown */
}

.create-journal-form label ,
.location-search-container label, 
.welcome-msg label{
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .create-journal-header {
    margin-left: 0;
  }

  .create-journal-header h2 {
    margin-left: 2vw;
    font-size: 2.3rem;
  }

  .create-journal-header p {
    margin-left: 2vw;
  }

  .create-journal-form label, 
  .location-search-container label, 
  .welcome-msg label {
    margin-left: 2vw;
    font-size: 1rem;
  }

  .mobile-button-container button[type="submit"] {
    width: 35%;
  }

  .mobile-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

}