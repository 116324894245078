/* manageJournal.css */

p {
    margin-left: 25px;
}

.delete-icon {
    cursor: pointer;
    margin-top: 1.5%;
}

.modal {
    font-family: 'Chakra Petch', sans-serif;
}

a { 
    margin-top: 10px;
    margin-left: 30px;
    font-family: 'Chakra Petch', sans-serif;
}

h3 {
    margin-top: 2vh;
}
label {
    margin-left: 25px;
    display: block;
    font-size: 16px;
    font-weight: bold;
}
    
/* New styles for header content and navigation menu */

.post {
    display: flex;
    background-color: #f0f0f0;
    padding: 1vw 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    align-items: center;
}

.post-header {
    flex: 1;
    margin-right: 20px;
    margin-left: 2vw;
}

.post-title {
    margin: 0;
    font-size: 1.8rem;
    color: #333;
    text-align: left;
}

.post-date {
    font-style: normal;
    color: #666;
    text-align: left;
    margin-bottom: 10px;
}

.post-body-posts {
    flex: 2;
    display: flex;
    flex-direction: flex;
    justify-content: flex-start;
}

.post-content {
    color: #444;
    margin-right: 25px;
}

.link-button:hover {
    background-color: #6bba7c;;
}

.manage-posts-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px; 
}

.manage-posts-title {
    flex-grow: 1;
    margin-right: 10px; 
}

.header-buttons {
    flex-shrink: 0;
}

.posts-list .post:nth-child(odd) {
    background-color: #d7d7d7;
}

/* Target every even-numbered child */
.posts-list .post:nth-child(even) {
    background-color: #e9e9e9; 
}

.manage-mapylon-link {

    text-decoration: none;
    color: white;
    margin-top: 0.5vh;
}

.manage-mapylon-link:hover {
    color: white;
    text-decoration: underline;

}


.view-map-btn, .create-new-btn, .view-journal-btn, .edit-journal-btn {
    background-color: #31a957; 
    color: white;
    border: none;
    padding: 8px 15px; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 14px; 
    cursor: pointer;
    border-radius: 3px; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 
}

/* Hover effect */
.view-map-btn:hover, .create-new-btn:hover, .view-journal-btn:hover, .edit-journal-btn:hover {
    background-color: #278e3b; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); 
}

.edit-settings-btn {
    background-color: #3193a9;
    color: white;
    border: none;
    padding: 8px 15px; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 14px;
    cursor: pointer;
    border-radius: 3px; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.edit-settings-btn:hover {
    background-color: #293d8e; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); 
}

.text-danger-loc {
    margin-left: 25px;
    color: red;
}

.mobile-mng-jrnl-btn.jrnl-btn {
    background-color: #31a957ad;
}

.copy-jrnl-url {
    padding: 0.5vh 1vh;
    margin-left: 1vh;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    background-color: #3c9b5aad;
    border: 1px solid #00000052;
}

.copy-jrnl-url.copied {
    background-color: #154624ad;
}

.copy-jrnl-url:hover {
    background-color: #154624ad;
}

.copy-icon {
    margin-left: 1vh;
}

@media (max-width: 768px) {
    .create-new-btn {
        width: 30vw;
        height: auto;
        margin-right: 5vw;
        background-color: #31a957ad;
        border: 1px solid #00000052; 
        display: inline-block;
        text-align: center;
    }


    .header-buttons {
        margin-right: 0;
    }

    .jrnl-create {
        margin-left: 10%;
    }

    .post-header h3 {
        font-weight: 600;
    }

    .post-title {
        font-size: 1.5rem;
    }

    .manage-posts-header h3 {
        font-weight: 1000;
        font-size: 2.3rem;
        margin-left: 5vw;
        margin-top: 1.5vh;
        margin-bottom: 1.5vh;
    }
}