
.welcome-message {

    background-color: rgba(107, 186, 124, 0.1); /* Optional: semi-transparent background */
    border-radius: 8px; /* Optional: rounded corners for the background */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
    max-width: 90%; /* Set a maximum width for better readability */
    text-align: center; /* Center align the text */
   
    margin-left: auto; /* Center the paragraph block */
    margin-right: auto;
    padding: 10px; /* Add some padding */



}

.welcome-message p {
    color: #333; 
    font-size: 16px; 
    line-height: 1.6; /* Set the line height for better readability */
    margin: 15px 0; /* Add some margin around the paragraph */
    
    font-family: 'Chakra Petch', sans-serif;
}

.welcome-message h3 {
    
    text-align: center; /* Center align the text */

}

.post-body {
    display: flex;
    justify-content: flex-end; /* Align buttons to the start of the container */
    gap: 10px; /* Add some space between the buttons */
}

.view-post-btn,
.edit-post-btn {
    flex-grow: 0; /* Prevent buttons from stretching */
    flex-shrink: 0; /* Prevent buttons from shrinking */
    /* Other styles for buttons */
}

