

.mobile-journal-img {
    margin-left: 3vw;
    height: 16vh;
    position: absolute;
    top: 1vh;
    width: auto;
    border-radius: 5%;
}

.manage-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mng-jrnl-logo-mobile {
    background-color: #31a957;
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 5vw;
    padding-left: 3vw;
    padding-right: 3vw;
    border-bottom-right-radius: 30%;
    border-bottom-left-radius: 30%;
    border: none;
}

.mng-jrnl-logo-mobile h5 {
    color: white;
    font-weight: 650;
    margin-bottom: 0.5rem;
    margin-top: 0.75rem;
}

@media (max-width: 768px) {

    .manage-logo, .mobile-nav-container {
        flex-shrink: 0;
        flex-grow: 1; 
    }

    .mobile-nav-container {
        display: flex;
        align-items: center; 
    }
    .manage-header {
        position: relative; 
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #f8f9fa;
        height: 18vh;
    }

    .manage-logo {
        max-height: 18vh;
    }
    
    .post-header {
        position: relative;
    }
    .manage-posts {
        max-width: 100%;
        background-color: #f8f9fa;
    }


    .mobile-journal-buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .mobile-mng-jrnl-btn {
        flex: 1; 
        display: flex; 
        justify-content: center; 
        align-items: center; 
        padding: 4%; 
        margin: 2% 4%;
        background-color: #31a9577d;
        border: 1px solid #00000052; 
        cursor: pointer; 
    }

    .hamburger-icon-jrnl {
        color: #164a26;
        position: absolute; 
        padding: 0;
        margin: 0;
        right: 5vw; 
        top: 35%;
        transform: translateY(-50%); 
        background: none;
        border: none;
        font-size: 44px;
        cursor: pointer;
    }

    
    .settings-icon, .fa-trash-alt, .fa-eye, .fa-pencil-alt {
        margin: 0 auto; 
    }
    

    .post {
        display: flex;
        flex-direction: column;
    }

    .post-body {
        width: 100%;

    }

    .post-header {
        width: 100%;
    }

    .journal-settings {
        position: absolute;
        margin-left: 2vw;
        cursor: pointer;
        top: 0;
        font-size: 80%;
        border: black;
        border-radius: 5%;
    }
}