.error-page-text {
    font-weight: 750;
    font-size: 2rem;
    color: white;
    margin-left: 0;
}

.error-page-buttons {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.error-page-button {
    color: black;
    font-family: 'Chakra Petch', sans-serif;
    background-color: rgba(255, 255, 255, 0.7);
    width: 10%;
    font-weight: 550;
}