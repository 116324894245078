.feature-color-input, 
.feature-color-fill, 
.feature-color-bg,
.shape-control,
.border-control {
    margin-left: 2%;
}

.color-picker-container label, .point-icon-container label  {
    margin-left: 2%;
    font-weight: 400;
    margin-bottom: 0;
}

.color-picker-container {
    margin-bottom: 1vh;
}

.point-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1vh;
}

.geometry-info .point-icon-container  button, 
.geometry-info .point-icon-container  button:hover {
    padding: 0;
    margin-left: 2%;
}

.icon-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
}

.icon-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    height: 70vh;
    overflow-y: auto;
    width: 45vw;
    z-index: 2000;
}

.icon-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 10px;
}

.icon-button {
    border: none;
    background: none;
    width: 4vw;
    padding: 0;
    cursor: pointer;

}

.icon-button:hover {
    background-color: rgb(184, 194, 203);
}