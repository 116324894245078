




.custom-card-header {
    min-height: 9vh;
    background-color: #31a957; 
    flex-shrink: 0; 
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    position: relative;
    padding-right: 40px
    
}

.custom-card-header h3 {
    color: white;
    font-weight: bold;
    margin-left: 4vh;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
    margin-right: 10px; 
    flex-grow: 1; 
}

.row h5 {
    margin-left: 5%;
    max-width: 90%;
}

.blog-popup-container {
    position: fixed;
    z-index: 100;
    left: 1%;  
    top: 2%;   
    width: 29%;
    height: 93vh;
    background-color: #f8f9fa; 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden; 
    
}


.blog-popup-body-container {
    overflow-y: auto; 
    flex-grow: 1; 
    padding: 10px;
    overflow-x: hidden;
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
}


.card-body {
    overflow-y: auto; 
    flex-grow: 1; 
    width: 95%;
    padding: 0px;
}





.custom-title {
    font-weight: bold;
    font-size: 26px;
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 0;
}




@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}




.card-footer {
    flex-shrink: 0;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd; 
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
}

.post-navigation-buttons {
    display: flex;
    justify-content: space-between;
    gap: 30px; 
    margin-bottom: 2vh;
}

.btn-move-posts, .btn-close-popup {
    background-color: #31a957; 
    color: white;
    border: none;
    padding: 8px 15px; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 14px;
    cursor: pointer;
    border-radius: 3px; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}


.btn-move-posts:hover, .btn-close-popup:hover{
    background-color: #278e3b;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); 
}

.popupUserArea {
    display: flex;
    flex-direction: row;
    --bs-gutter-y: 0;
}

.btn-close-popup-header {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    right: 0;
    border: none;
    background: none;
    font-size: 1.5rem;
    color: #ffff;
    cursor: pointer;
    border-color: #ffff;
    border-radius: 5px;
}

custom-card-header {
    position: relative;
}

.warning-message-container {
    margin-bottom: 5px; 
    text-align: center; 
}

.warning-message {
    color: red; 
    font-weight: bold;
    font-family: 'Chakra Petch', sans-serif;
}

.image-container {
    display: flex;
    max-width: 100%;
    height: 42vh;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    overflow: hidden;
    margin-top: 0;
}


.row .img-thumbnail {
    margin-left: 0;
    display: flex;
    max-height: 100%;
    border-radius: .3rem;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    padding: 0;
}

.image-gallery .img-thumbnail {
    width: auto;
    max-width: 80%;
    max-height: 34vh;
    border-radius: .25rem;
    object-fit: contain;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;
}

.image-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 34vh;
    position: relative; 
}
.gallery-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.gallery-button {
    background: none;
    border: none;
    color: #000;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0 10px;
    padding: 5px;
}

.gallery-button:hover {
    background-color: none;
    background: none;
}

.gallery-button:first-of-type {
    left: 20px; 
}

.gallery-button:last-of-type {
    right: 20px; 
}

.gallery-navigation p {
    
    margin: 0; 
    margin-top: 5px;
    font-size: 12px; 
    color: #333; 
    margin-left: 0;
    

}
.img-thumbnail.img-fluid {
    max-width: 90%;
    height: 100%; 
}

.image-gallery p {
    margin-top: 10px; 
    text-align: center; 
    font-size: 0.9rem; 
    color: #333; 
    width: 100%; 
    margin-bottom: 0;
    margin-left: 0;
}

.custom-text {
    margin-left: 5%;
    margin-top: 0;
    margin-bottom: 0;
}

.loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    color: #999;
}


.spinner-img {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #31a957;
    animation: spin-img 1s ease infinite;
    font-family: 'Chakra Petch', sans-serif;
}

@keyframes spin-img {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.popup-terrain-stats {
    display: flex;
    flex-direction: row;
}

.journal-view-container {
    position: fixed;
    z-index: 110;
    left: 1%;  
    top: 2%;   
    width: 29%;
    height: 93vh;
    background-color: #f8f9fa; 
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden; 
}

.open-journals-link {
    cursor: pointer;
    color: blue;
}

.popupUserArea p {
    margin-bottom: 0;
}

.open-elevation-button {
    background-color: rgba(0,0,0,0);
    color: blue;
    text-decoration: underline;
    padding: 0;
    margin-left: 5%;
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Chakra Petch', sans-serif;
}

.open-elevation-button:hover {
    background-color: rgba(0,0,0,0);
}

@media (max-width: 768px) {
    .blog-popup-container {
        left: 0;  
        bottom: 0;
        top: auto; 
        width: 100vw;
        height: 46vh;
        border-radius: 15px;
    }

    .custom-card-header h3 {
        font-size: 1.2rem;
        margin-top: 1vh;
        margin-left: 3vw;
    }
    .custom-card-header {
        min-height: 1vh;
    }

    .blog-popup-body-container h5 {
        font-size: 1rem;
        margin-left: 1vw;
    }

    .blog-popup-body-container p {
        font-size: 0.6rem;
        margin-left: 1.1vw;
        margin-right: 1vw;
    }

    .btn-close-popup-header {
        font-size: 1rem;
        top: 1vh;
    }

    .image-container {
        height: 30vh;
    }

    .image-gallery {
        height: 24vh;
    }

    .image-gallery {
        display: flex; /* Align images horizontally */
        overflow-x: auto; /* Enable horizontal scrolling */
        scroll-snap-type: x mandatory; /* Ensure each image aligns to the gallery's viewport */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS devices */
    }
    
    .img-thumbnail {
        flex: 0 0 auto; /* Prevent images from shrinking */
        width: 100%;
        scroll-snap-align: start; /* Align images to the start edge of the gallery viewport */
        display: block; /* Override any existing display: none; when the image is loaded */
    }

    
}

