.logo-and-title {
    position: absolute;
    top: 3%;
    left: 2%;
    display: flex;
    align-items: center;
    z-index: 20; 
}

.star-marker, .camera-marker {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: rgb(0, 0, 88); 
    font-size: 1rem;
    border: 1px solid black; 
}

.footer-layout {
    max-height: 90vh;
}

.map-logo p {
    color: white;
    font-weight: bold;
    margin-left: 0;
    margin-bottom: 0;
}

.map-logo {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.map-footer {
    bottom: 0;
    background-color: white;
    position: absolute;
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 10px; 
    flex-shrink: 0;
}

.footer-buttons button {
    margin-left: 10px; 
    font-family: 'Chakra Petch', sans-serif;
    font-weight: bold;
}

.map-footer p {
    margin: 0;
    font-size: 1.2rem;
    padding: 0;
    font-family: 'Chakra Petch', sans-serif;
}

.gallery-button:hover {
    background-color: none;
}

.view-map {
    flex: 1 0 auto; 
    display: flex; 
    position: relative; 
    overflow: hidden; 
}

.view-map-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.toggle-3d-button {
    cursor: pointer;
    padding: 8px 12px;
    background-color: #31a957;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.logo-and-title {
    top: '3%';
    left: '2%',
}

.map-logo p {
    font-size: 0.7rem;
    color: #171244;
}

.map-logo {
    width: 5vw;
    cursor: pointer;
}

.map-logo img {
    border-radius: 5px;
}

.graph-container {
    z-index: 1500;
    width: 30%;
    margin-left: 33%;
    height: 40vh;
    margin-top: 3vh;
    position: absolute;
}

.graph-canvas {
    position: absolute;
    width: 100%;
    background-color: white;
    padding: 2%;
    border-radius: 5px;

}

@media (max-width: 768px) {
    .logo-and-title h2 {
        font-size: 1.3rem;
        color: #171244;
    }

    .map-logo p {
        font-size: 0.5rem;
        color: #171244;
    }

    .logo-and-title {
        top: 1vh;
        /* left: 2%; */
    }

    .map-logo {
        width: 13vw;
    }

    .mobile-blog-header {
        color: rgb(15, 9, 39); 
        text-align: center; 
        position: fixed; 
        top: 0; 
        left: 0;
        right: 0; 
        background-color: rgba(255, 255, 255, 0.3);
        z-index: 1000; 
    }

    .mobile-blog-header h3 {
        font-size: 1.5rem;
        margin-right: 0;
        margin-left: 0;
        font-weight: 550;
        margin-bottom: 0.5vh;
        margin-top: 0.75vh;
    }

    .mobile-blog-header p {
        font-size: 0.75rem;
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0;
    }

    .map-footer p {
        font-size: 0.75rem;
    }
    
    .footer-buttons button {
        font-size: 0.75rem;
        padding: 0.5vh 1vh;
    }

    .toggle-3d-button {
        bottom: 6vh;
        right: 0vw;
        margin-right: 2vw;
    }
}