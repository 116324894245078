.camera-icon-marker, .camera-icon-marker-editing {
    width: 30px;
    height: 30px;
  
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px; 
    color: black; 
    border: 1px solid black; 
  }

  .left-geometry-info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .left-geometry-info h5 {
    padding: 0;
    /* margin-top: 2vw; */
    margin-bottom: 0;
    margin-left: 1vw;
    font-size: 1.5rem;
  }