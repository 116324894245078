/* authStyles.css */
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
}

.auth-form button.login-button[type="submit"] {
    width: 100%;

}
.auth-form {
    padding: 20px;
    background-color: #43a4d7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center; 
    display: flex; 
    flex-direction: column;
    align-items: center; 
}

.auth-form p {
    color: #ffffff;
    margin-top: 0;
    font-family: 'Chakra Petch', sans-serif;
}
.auth-form img {
    margin-top: 4% !important;
    margin-bottom: 0 !important;
}

.forgot-pw a {
    margin-left: 0;
}

.auth-form h2 {
    margin-bottom: 15px;
    margin-left: 0;
    font-size: 36px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-form h2 img {
    margin-right: 30px; /* Space between image and text */
}

.auth-form button[type="submit"] {
    margin-left: 0;
    margin-right: 0;
    font-family: 'Chakra Petch', sans-serif;
    width: 80%;
}

.centered-content {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center;
}

.auth-form .auth-input {
    margin-left: 0;
    width: 95%;
    padding: 10px;
    font-family: 'Chakra Petch', sans-serif;
    margin-bottom: 15px;
    display: block;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 10px auto;
}


.mb-3 input[type="text"], input[type="password"] {
    width: 100%;
    margin-left: 0;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Chakra Petch', sans-serif;
}


.auth-form .auth-button {
    margin-left: 0;
    width: 60%;
    padding: 10px;
    margin: 10px auto;
    border: none;
    border-radius: 4px;
    background-color: #31a957;
    font-family: 'Chakra Petch', sans-serif;
    color: white;
    cursor: pointer;
    font-size: 16px;
}

.auth-form button:hover {
    background-color: #278e3b;
}

.forgot-password {
    text-align: center;
    margin-left: 0;
}

.forgot-password a {
    color: #007bff; /* or any color you prefer */
    text-decoration: underline;
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 0;
}

/* Spinner Overlay Styles */
.spinner-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
  }
  
  @keyframes spin {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  
  .spinner-logo {
    width: 120px; 
    height: auto;
    border-radius: 2vh;
    animation: spin 2s linear infinite;
  }
  
@media (max-width: 768px) {

    @keyframes pulse {
        0%, 100% {
          transform: scale(0.7);
        }
        50% {
          transform: scale(1.1);
        }
      }
      .spinner-logo {
        animation: pulse 2s infinite ease-in-out;
      }
      


    .mobile-login {
        background-color:rgb(67, 164, 215, 0.75);
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mb-3 input[type="text"], input[type="password"] {
        width: 85%;
        margin-left: 7.5%;

    }

    .auth-form button.login-button[type="submit"] {
        padding-top: 2%;
        padding-bottom: 2%;
        width: 70%;
        margin: 0;
        text-align: center;
    }

    .forgot-pw a {
        margin-left: 0;
        color: white;
    }

    .login-title {
        font-weight: 650;
    }
}