/* src/components/additionalSignup.css */


.extended-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /*background: url('/static/images/background7.jpg') no-repeat center center;*/
    background-size: cover;
}

.extended-info-form {
    padding: 30px;
    height: auto;
    background-color: #43a4d7;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    max-width: 40%;
    width: 100%;
    text-align: center;
    align-items: center;
    overflow-y: auto;
    max-height: 90%;
}

.extended-info-form input[type="text"] {
    padding: 7px;
    margin-top: 1%;
}

.extended-info-form h4 {
    margin-top: 0;
    font-family: 'Chakra Petch', sans-serif;
    margin-bottom: 3%;
    font-size: 36px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.extended-info-form h4 img {
    margin-right: 20px; /* Space between image and text */
}

.extended-info-form p {
    color: #ffffff;
    margin: 10px auto;
    margin-bottom: 20px;
    font-family: 'Chakra Petch', sans-serif;
}

.extended-info-form label {
    color: #ffffff;
    margin-left: 15px;
    font-size: 18px;
    margin-bottom: 0;
    font-family: 'Chakra Petch', sans-serif;
    text-align: left;
}

.extended-info-form input {
    margin-left: 0;
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    display: block;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 10px auto;
}

.extended-info-form .auth-button {
    width: 60%;
    padding: 12px;
    margin: 15px auto;
    margin-left: 0;
    border: none;
    border-radius: 5px;
    background-color: #31a957;
    color: white;
    cursor: pointer;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 18px;
}

.extended-info-form button:hover {
    background-color: #0056b3;
}
