.color-overlay-about {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
        to right, 
        rgb(27, 32, 54) 0%,
        rgb(27, 32, 54) 25%,
        rgba(0,0,0,0) 60%);
}



.about-text-overlay {
    position: absolute;
    height: 100vh;
    width: 100%;
}

.home-title.about {
    font-size: 4vw;
}

.home-subheading.about {
    font-size: 1.3vw;
}

.home-signup-button.about-main {
    font-size: 1.3vw;
    font-weight: 550;
}

.small-image.home-logo-img { 
    cursor: pointer;
}