.walkthrough-popup {
    position: fixed; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px; 
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1000; 
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 80%;
    overflow-y: auto;
}
  
.walkthrough-footer {
    margin-top: 20px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}
.walkthrough-popup p {
    margin-left: 0;
    text-align: center;
}

.walkthrough-popup h5 {
    color: #20783c;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 750;
    font-size: 1.5rem;
    margin-left: 0;
}

.walkthrough-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 999; 
}
  
.walkthrough-close-btn {
    background-color: #31a957;
    border: none;
    font-family: 'Chakra Petch', sans-serif;
    cursor: pointer;
    font-size: 16px;
    z-index: 1001;
    margin-left: 20%;
}
  
 
.walkthrough-close-btn:hover {
    opacity: 0.8;
}

  /* Adjustments for checkbox alignment */
#dontShowAgain {
    margin-right: 10px;
}
  
  /* Optional: Adjust the label and checkbox alignment if necessary */
.walkthrough-popup label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .walkthrough-footer {
        display: flex;
        flex-direction: column;
    }

    .no-show-check {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .walkthrough-popup label {
        margin-left: 2vw;
        font-size: 0.9rem;
    }

    .walkthrough-close-btn {
        margin-left: 3vw;
        margin-right: 3vw;
    }
}