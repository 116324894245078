/* projectStyles.css */
body, html {
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
    height: 100%;
}

.header {
    display: flex;
    background-color: #6bba7c;
    color: #fff;
    padding: 15px;
    text-align: left;
    height: auto;
}
.small-image {
    margin-left: 25px;
    width: 100px; 
    height: auto;
}

.small-image-2 {
    margin-left: 25px;
    width: 55px; 
    height: auto;
}

.large-image {
    margin-left: 25px;
    width: 700px; 
    height: auto;
}

.page-container {
    display: flex;
    flex-direction: column; 
    height: 90vh;
}

h3 {
    margin-left: 3%; 
    margin-bottom: 2%;
    font-size: 30px;
    font-family: 'Chakra Petch', sans-serif;
    padding: 0;
}

h2 {
    margin-left: 3%; 
    font-size: 30px;
    font-family: 'Chakra Petch', sans-serif;
    margin-bottom: 0;
    padding: 0;
}

.header-content {
    flex-grow: 1; 
}

.header h2 {
    margin-left: 3%; 
    font-size: 30px;
    font-family: 'Chakra Petch', sans-serif;
    margin-bottom: 0;
    padding: 0;
}

.header-nav ul {
    margin-top: 1.5%;
    margin-left: 3%; 
    list-style: none; 
    padding: 0; 
    display: flex; 
}

.header-nav li {
    margin-right: 31px;
}

.header-nav a {
    text-decoration: none; 
    color: #fff;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 20px;
}

.link-button, .link-button-create {
    background: none;
    margin-left: 31px;
    color: white;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Chakra Petch', sans-serif;
}

.link-button-create {
    padding: 0; 
    white-space: nowrap;
    margin-top: 0;
    margin-bottom: 0;
}

.link-button-create:hover {
    text-decoration: underline;
    background-color: rgba(0,0,0,0);
}