/* manageJournal.css */

p {
    margin-left: 25px;
}

a { 
    margin-top: 10px;
    margin-left: 30px;
}

label {
    margin-left: 25px;
    display: block;
    font-size: 16px;
    font-weight: bold;
}
    
/* New styles for header content and navigation menu */

.post {
    display: flex;
    background-color: #f0f0f0;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    align-items: center;
}

.post-header {
    flex: 1;
    margin-right: 20px;
    margin-left: 25px;
    width: 30%;
}

.post-title {
    margin: 0;
    font-size: 1rem;
    color: #333;
    text-align: left;
}

.post-title-draft {
    margin-left: 1vw;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 22px;
    color: #ff0000;
    text-align: left;
}

.post-date {
    font-style: normal;
    color: #666;
    text-align: left;
    margin-bottom: 10px;
    font-size: 1.25rem;
}

.post-body-posts {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    margin-right: 4vw;
}

.post-content {
    color: #444;
    margin-right: 25px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; 
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.view-post-btn, .edit-post-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 25px;
}
.manage-posts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manage-posts-title {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

}

.header-buttons {
    display: flex;
    flex-shrink: 0;
    margin-right: 0;
}

.header-buttons .view-map-btn {
    margin-right: 20px;
}

.view-post-btn, .edit-post-btn {
    color: white;
    border: none;
    padding: 8px 15px; /* Adjust padding for sleek look */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 14px; /* Smaller font size for sleeker look */
    cursor: pointer;
    border-radius: 3px; /* Rounded corners */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for background color and shadow */
}

/* Hover effect */
.view-post-btn:hover {
    background-color: #278e3b; /* Darker shade on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Subtle shadow on hover */
}

.view-post-btn {
    background-color: #31a957; /* Theme color */
}

.edit-post-btn {
    background-color: #3175a9; /* Theme color */
}

/* Hover effect */
.edit-post-btn:hover {
    background-color: rgb(33, 79, 114); /* Darker shade on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Subtle shadow on hover */
}

.manage-posts-title h5 {
    font-family: 'Chakra Petch', sans-serif;
    margin-left: 3vw;
    font-weight: 200;
    margin-bottom: 0;
}

.manage-posts-title h2, .manage-posts-header h2 {
    font-size: 3rem;
    margin-left: 3vw;
}



.small-image.mng {
    border-radius: 1vh;
    width: 5vw;
    margin-left: 2vw;
    cursor: pointer;
}
.header.mng-header {
    padding: 1vw, 15px;
}

.header-nav.mng a, .header-nav.mng .link-button-create {
    color: #001422;
}

.header.mng-header, .page-container.mng-posts{
    background-color: #f8f9fa;
}

.header-buttons .mobile-mng-jrnl-btn, .mobile-mng-jrnl-btn.view {
    background-color: #31a957ad;
}

.mobile-mng-jrnl-btn .text-icon, .create-new-btn .text-icon {
    margin-right: 1vw;
}

.mobile-mng-jrnl-btn {
    font-family: 'Chakra Petch', sans-serif;
    border: 1px solid #00000052;
}



.mobile-mng-jrnl-btn.edit {
    background-color: #0b82d24d; 
}

.mobile-mng-jrnl-btn.dlt {
    background-color: #d20b0b4d; 
}

.header-buttons .mobile-mng-jrnl-btn:hover, .mobile-mng-jrnl-btn.view:hover, .mobile-mng-jrnl-btn.jrnl-btn:hover {
    background-color: #1c5c30ae; 
}

.mobile-mng-jrnl-btn.edit:hover {
    background-color: #04314f82; 
}

.mobile-mng-jrnl-btn.dlt:hover {
    background-color: #56040494; 
}

.manage-posts-title h3 {
    margin-bottom: 0;
    margin-top: 0;
}

.manage-posts-title a {
    margin-left: 3%;
}

.manage-posts p {
    margin-left: 3%
}

.post-title-container {
    width: 40vw;
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) { 
    .header-buttons-posts {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .manage-posts .post-date {
        margin-bottom: 0;
        font-style: normal;
        font-size: 1rem;
    }
    .manage-posts .dlt {
        background-color: #d20b0b4d;
    }

    .manage-posts .manage-posts-title h3 {
        margin-top: 0;
    }

    .manage-posts-title h5, .manage-posts-title h3 {
        margin-left: 5vw;
    }

    .header-buttons-posts a {
        margin-left: 0;
    }

    .manage-posts-header, .page-container {
        background-color: #f8f9fa;
    }

    .post-title-container {
        width: 100%;
    }

    .post-content {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; 
    }
}