

.view-journals-popup-container {
    z-index: 110;
}

.journal-expand-btn {
    padding: 2%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-top: 0;
    margin-left: 0;
}

.viewjournal-popup-body-container h5 {
    font-size: 1.2rem;
    margin-left: 4vh;
    cursor: pointer;
}

.viewjournal-popup-body-container h4 {
    font-size: 1.5rem;
    margin-left: 0;
    margin-top: 1vh;
    margin-bottom: 2%;
    margin-left: 3vh;
}

.viewjournal-popup-body-container ul {
    margin-left: 10vh;
    margin-top: 0;
}

.swiper-slide {
    padding: 0 !important;
    margin: 0 !important;
}
