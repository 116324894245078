/* createBlogPost.css */

.responsive-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body, html {
  margin: 0;
  padding: 0;
}

.HeaderManage {
  margin: 0;
  width: 100%;
}

img, table {
  max-width: 100%;
  height: auto;
}

.content, .form-container {
  z-index: 2; 
  background-color: rgba(255, 255, 255, 0.9); 
  padding: 20px;
  /* margin: 20px; */
  border-radius: 5px;
}


.form-group .post-body-textarea {
  margin-left: 25px;
  width: 90%;
  height: 20vh;
  border-radius: 5px;
  font-family: 'Chakra Petch', sans-serif;
  margin-bottom: 1.5vh;
}


label {
  margin-left: 25px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.feature-naming-modal label,
.feature-naming-modal input[type="text"],
.feature-naming-modal button {
  margin-left: 0;
  display: block; 
  width: 100%; 
  margin-bottom: 8px; 
}

input[type="text"],
input[type="date"],
textarea {
  margin-left: 25px;
  width: 90%;
  padding: 5px;
  border-radius: 5px;
  font-family: 'Chakra Petch', sans-serif;
}

button[type="save"] {
  margin-left: 25px;
  background-color: #31a957;
  color: #fff;
  width: 24%;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

button[type="save"]:hover {
  background-color: #216c39;
}

button[type="submit"] {
  margin-left: 25px;
  background-color: #31a957;
  color: #fff;
  width: 24%;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}
  
button[type="submit"]:hover {
  background-color: #216c39;
}


.content-container {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
}

.form-section {
  flex: 1;
}

.map-container-post {
  /* flex: 1;
  position: relative;
  padding: 20px;
  height: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#mapbox {
  width: 100%; 
  height: 85%; 
  background-color: #eaeaea; 
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mapbox-container-box {
  height: 100%;
}

.map-buttons {
  position: absolute;
  top: -20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 2
}

.map-btn {
  background-color: #007bff;
  color: white;
  border: none;
  width: 90px; 
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.expand-map-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: #007bff;
  color: white;
  border: none;
  width: 30px; 
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
}



.feature-naming-modal {
  position: absolute;
  bottom: 25px;         
  right: 25px;
  background-color: white; 
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.form-group .title-input {
  margin-left: 25px;
  width: 90%;
  border: none;
  outline: none;
  padding: 10px 0;
  font-family: 'Chakra Petch', sans-serif;
  font-size: calc(1rem + 0.8vw);
  background-color: transparent;
  color: #333; 
  border-bottom: 2px solid rgb(41, 41, 41, 0.8);
}

.title-input::placeholder {
  color: #aaa; 
}

.title-input:focus {
  border-bottom: 2px solid #007bff; 
}

.photo-popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body {
  overflow-x: auto; 
}



.form-group input, .form-group textarea {
  width: 90%;
  box-sizing: border-box; 
  font-size: calc(0.5rem + 0.5vw);
}

.feature-naming-modal {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  right: 20px; 
  bottom: 20px;
  max-width: 300px; 
  z-index: 10;
  animation: fadeIn 0.3s;
}



.feature-naming-modal input,
.feature-naming-modal button {
  padding: 8px;
  border-radius: 4px;
}

.feature-naming-modal input {
  border: 1px solid #ddd;
}

.feature-naming-modal button {
  border: none;
  background-color: #31a957;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.feature-naming-modal button:hover {
  background-color: #216c39;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header .close {
  margin: 0;
  padding: 1rem;
  background: none;
  border: none;
}

.modal-title {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.modal-title h3 {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1rem; 
}



.photo-thumbnail-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.photo-thumbnail {
  display: block;

}

.trash-icon, .globe-icon {
  color: red; 
  position: absolute;

  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7); 
  border-radius: 50%;
  padding: 0; 
  font-size: 16px;
  line-height: 20px; 
  width: 20px; 
  height: 20px; 
  text-align: center;
  box-sizing: border-box;
  display: flex; 
  align-items: center; 
  justify-content: center; 
}

.trash-icon {
  top: 3%;
  right: 3%;
}

.globe-icon {
  top: 3%;
  left: 3%;
}

.error-message {
  margin-left: 25px;
  font-family: 'Chakra Petch', sans-serif;
}

.error-message-map {
  font-family: 'Chakra Petch', sans-serif;
}

.camera-icon-marker, .camera-icon-marker-editing {
  width: 30px;
  height: 30px;

  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; 
  color: rgb(0, 0, 88); 
  border: 1px solid black; 
}

.camera-icon-marker-editing {
  background-color: yellow;
}

.camera-icon-marker {
  background-color: white;

}

.custom-file-upload {
  display: flex;
  align-items: center; 
  
}

.custom-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.custom-file-button {
  margin-left: 25px;
  font-family: 'Chakra Petch', sans-serif;
  border: 1px solid #ccc;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.photo-upload-label {
  font-weight: normal;
  font-family: 'Chakra Petch', sans-serif;
  margin-left: 25px;
}

.processing-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  display: flex;
  flex-direction: row;
  flex-grow: 1; 
  overflow: hidden; 
}

.processing-popup p {
  font-size: 24px;
  color: white;
  text-align: center;
}

.uploading-photos-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.uploading-photos-popup p {
  font-size: 24px;
  color: white;
  text-align: center;
}

.header-post {
  z-index: 10;
  background-color: rgba(0,0,0,0);
  width: 65vw;
}

.header-post .header-nav {
  margin-top: 0;
}

.header-journal-entry {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) { 
  .page-body-container {
    flex-direction: row;
  }

  .form-section {
    padding-right: 15px; 
  }
}

.brand-link {
  text-decoration: none; 
  color: inherit; 
}

.form-container {
  /* flex: 1;  */
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  overflow-y: auto;
  width: 36.5vw;
}

.map-container {
  flex: 1; 
  padding-top: 10px;
  padding-right: 40px;
  padding-left: 20px;
  padding-bottom: 20px;
  position: relative;
}

.header-nav li .link-button {
  margin-left: 30px;
  margin-top: 0;
  white-space: nowrap; 
  margin-bottom: 0;
}

.journal-nav-container a:hover, .header-nav a:hover {
  text-decoration: underline;
}

form-container input,
.form-container textarea {
  width: calc(100% - 40px); /* Adjust width based on padding */
  margin: 10px 20px;
  padding: 10px;
  border-radius: 5px;
}

.custom-file-button {
  background-color: #31a957;
}

/* Style adjustments for responsive and accessible design */
button {
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px 20px;
  border-radius: 5px;
  background-color: #31a957;
  color: white;
  border: none;
}

button:hover, .custom-file-button:hover {
  background-color: #216c39;
}

/* Ensure all text is easily readable and elements are accessible 
label, .error-message {
  display: block;
  margin: 0 20px;  
  color: red;
} */




.photo-thumbnails {
  max-height: 25vh; 
  overflow-y: auto; 
  overflow-x: auto; 
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px; 
  margin-left: 12.5px;
  width: 100%;
  /* padding-top: 10px; */
}

.photo-thumbnail {
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 100px;
  object-fit: cover; 
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); 
}

.naming-photo-popup {
  max-height: 30vh;
  border-radius: 5px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); 
  margin-bottom: 3vh;
}

#photos {
  margin-left: 25px;
}

.photo-thumbnails img {
  max-width: 100%;
  height: 10vh;
  border-radius: 5px;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-group {
  display: flex; 
  flex-direction: row; 
  justify-content: start; 
  flex-wrap: nowrap; 
}

.mapboxgl-ctrl-top-left {
  top: 0; 
  left: 0; 
  position: absolute;
}

.mapboxgl-control-container button {
  margin: 2%;
  border: 2px solid transparent;
  background-color: #fff;
}

.mapbox-gl-draw_ctrl-draw-btn {
  border: 2px solid transparent;
  background-color: #fff;
}

.geometry-info {
  /* display: flex; */
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  border-radius: 1.5vh;
  overflow-y: auto;
  z-index: 2;
  flex-direction: row;
  font-family: 'Chakra Petch', sans-serif;
}

.geometry-wrapper {
  overflow: hidden;
  border-radius: 1.5vh;
  bottom: 5vh;
}


.geometry-info input[type="text"] {
  margin-left: 2%;
  padding: 0;
}

.geometry-info button {
  margin-left: 2%;
  color: black;
  background-color: #aaaaaa;
  padding: 0 3%;
  margin-top: 0;
  margin-bottom: 0;
}

.geometry-info button:hover {
  margin-left: 2%;
  color: white;
  background-color: #656565;
  padding: 0 3%;
}

.geometry-table {
  overflow-y: auto;
}

/* .geometry-info .geometry-table,
.geometry-info .edit-feature, .create-feature {
  flex: 1 1 50%;
} */

.edit-feature p {
  margin-left: 2%;
  margin-bottom: 1vh;
}

.geometry-info .edit-feature h5 {
  font-size: 2.5vh;
  padding-top: 0;
  margin-bottom: 1vh;
  padding: 0;
}

.geometry-info h5 {
  padding: 2%;
  margin-left: 0;
}

.geometry-info table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: hidden;
}

.geometry-info th, .geometry-info td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.geometry-info th {
  background-color: #f2f2f2;
}

.geometry-table, .edit-feature {
  /* flex: 1;  */
  overflow-y: auto; 
}


.edit-feature {
  padding-left: 0;
}

.brand-link {
  margin-top: 0;
  margin-left: 1.5vw;
  background-color: rgb(49, 170, 88, 0.9);
  padding: 0 1vw;
  margin-top: 2vh;
  border-radius: 3px;
}

.header-post ul {
  margin-bottom: 0;
  align-items: center;
  font-weight: 600;
  color: #171244;

}

.journal-nav-container {
  margin-top: 1.6vh;
}

.header-post h3 {
  color: white;
  font-size: 2rem;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0.2rem;
  line-height: auto;
}

.custom-control {
  background-color: #dedddd;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
  border-radius: 25%;
  padding: 8%;
}

.custom-control:hover {
  background-color: #eef0f1;
}

.body {
  background-color: rgba(0,0,0,0) !important;
}

button.save-entry-btn {
  background-color: #4ca8f9;
  cursor: pointer;

}

button.save-entry-btn:hover {
  background-color: #3474ac;
}

.save-entry-btn, button.publish-entry-btn[type="submit"] {
  margin-top: 3vh;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 1.2rem;
  width: 29%;
  padding: 1vh 1vw;
}



.mapboxgl-popup-close-button {
  margin: 0;
  padding: 0;
  color: black;
}

.form-wrapper {
  position: relative; 
}

.form-container {
  transition: all 0.3s ease; 
  height: 85vh;
}

.toggle-form-arrow.minimized-form {
  right: -2vh;
}

.toggle-form-arrow {
  position: absolute;
  top: 0.5vh; 
  right: 0.5vh; 
  cursor: pointer;
  padding: 1vh; 
  background-color: #31a957;
  transition: transform 0.3s ease;
  border-radius: 0.5vh; 
}

.toggle-form-arrow i {
  /* margin-bottom: 1.5vh; */
  color: white;
}


.toggle-form-arrow:hover {
  background-color: #e0e0e0;
}

/* .minimized-form {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4vh;
} */

.vertical-text {
  /* transform: rotate(180deg); */
  /* transform-origin: left bottom; */
  writing-mode: vertical-lr;
  text-align: center;
}

.min-form-create {
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.min-form-create max {
  margin-bottom: 0;

}
.min-form-create p {
  margin-left: 0;
  margin-top: 2vh;
  margin-bottom: 0;
  color: white;
  margin-right: 0;
  font-weight: 550;
}

.tooltip-text {
  visibility: hidden; 
  width: 10vw; 
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  

  position: absolute;
  z-index: 1;
    top: 50%; 
    left: 100%;
    margin-top: -15px; 
    margin-left: 10px; 
  
  ::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
  }
}

.toggle-form-arrow:hover .tooltip-text {
  visibility: visible; /* Show tooltip on hover */
}


.toggle-form-arrow-geo {
  position: absolute;
  top: 0; 
  right: 0; 
  cursor: pointer;
  padding: 10px; 
  background-color: #f0f0f0;
  transition: transform 0.3s ease; 
  overflow: hidden;
}


.toggle-form-arrow-geo:hover {
  background-color: #e0e0e0;
}

/* .hide-show-geo-pan {
  position: absolute;
  right: 0;
  z-index: 1500;
} */


.hide-show-geo-pan button {
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 1vh;
  height: 4vh;
}
.routes-min-btn p {
  margin: 0;
}
.hide-show-geo-pan .max{
  margin-left: 2vw;
  margin-bottom: 0;
}

.hide-show-geo-pan.minimized button {
  margin: 0;
}

.hide-show-geo-pan.minimized {
  bottom: 0; 
  right: 2vw;

}

.feature-name-input {
  margin-bottom: 1vh;
}

.route-edit-cxl, .route-edit-save {
  font-size: 1.5vh;
}

.edit-feature .route-edit-save {
  background-color: #367349;
  color: white;
}

.edit-feature .route-edit-save:hover {
  background-color: #184e29;
}

.geometry-info .route-edit-cxl:hover {
  background-color: #444444;
}
.hide-show-geo-pan {
  position: absolute;
  right: 5vw;
  bottom: 33vh;
  z-index: 1500;
}

.geometry-wrapper.none {
  height: 0;
  width: 0;
  bottom: 0;
}

.geometry-wrapper {
  height: 35vh;
  position: absolute;
  width: 40vw;
  left: 55vw;
  background-color: rgba(0,0,0,0)
}

/* .hide-show-geo-pan i {
  margin-bottom: 1.5vh;
} */

@media (max-width: 768px) {
  .routes-min-btn p {
    margin: 0;
  }
  .form-container {
    width: 90vw;
  }

  .header-journal-entry {
    margin-left: 4vw;
  }

  .brand-link {
    background-color: rgba(49,170,88,0.9);
  }

  .search-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .search-modal-content {
    margin-bottom: 20vh;
    padding: 2vh;
    height: 22vh;
    width: 80vw;
    z-index: 1000;
    background-color: white;
    border-radius: 10%;
  }


  .geometry-table button {
    padding: 10% 10%;
    background-color: #d20b0b4d;
  }

  .geometry-wrapper {
    height: 25vh;
    position: absolute;
    width: 90vw;
    left: 5vw;
    bottom: 5vw;
    border-radius: 1.5vh;
    background-color: rgba(0,0,0,0)
  }



  .search-close {
    margin-bottom: 3vh;
  }

  .hide-show-geo-pan {
    position: absolute;
    right: 5vw;
    bottom: 33vh;
    z-index: 1500;
  }

  .hide-show-geo-pan button {
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding: 1vh;
    height: 4vh;
  }

  .hide-show-geo-pan .min {
    margin-bottom: 1.5vh;
  }
  .hide-show-geo-pan .max{
    margin-left: 2vw;
    margin-bottom: 0;
  }

  .hide-show-geo-pan.minimized button {
    margin: 0;
  }

  .hide-show-geo-pan.minimized {
    bottom: 0; 
    right: 0;

  }

  .form-group .title-input {
    margin-left: 0;
  }

  .form-group input[type="date"] {
    margin-left: 0;
    width: 100%;
  }
  
  .photo-upload-label {
    font-size: 0.75rem;
  }

  .custom-file-button {
    margin-left: 0;
    margin-right: 2vw;
    font-size: 0.75rem;
  }

  .form-group label {
    margin-left: 0;
  }

  .form-group .post-body-textarea {
    margin-top: 0;
    margin-left: 0;
    width: 100%
  }

  .save-entry-btn, button.publish-entry-btn[type="submit"] {
    width: 40vw;
    margin-right: 0;
    margin-left: 0;
  }

  .form-button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .photo-thumbnails {
    margin-left: 0;
    gap: 2%;
    
  }

  .photo-thumbnails img {
    height: 7.5vh;
    margin-bottom: 2vh;
  }

}