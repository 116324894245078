/* homeSplashPage.css */

.page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

p {
  font-family: 'Chakra Petch', sans-serif;
}

.display-4 {
  font-weight: 800;
}

.header-logo {
  text-align: center;
}

/* .text-center.text-white.home-center {
  border: 2px solid white;
  background-color: rgba(223, 239, 236,0.5);
  padding: 3%;
  border-radius: 4vh;
} */

.home-title {
  margin-left: 0;
  font-size: 5rem;
  color:#fff;
  font-weight: bolder;
  line-height: 1.05;
  font-family: 'Chakra Petch', sans-serif;
  /* text-shadow:
    -1px -1px 0 #003c04,  
     1px -1px 0 #003c04,
    -1px  1px 0 #003c04,
     1px  1px 0 #003c04; */
}

.home-subheading {
  margin-left: 0;
  margin-top: 2vh;
  font-size: 1.5rem;
  font-weight: 400;
  color:#fff;
  line-height: 1.5;
  font-family: 'Chakra Petch', sans-serif;
  /* text-shadow:
  -1px -1px 0 #003c04,  
   1px -1px 0 #003c04,
  -1px  1px 0 #003c04,
   1px  1px 0 #003c04; */
}

.home-signup-button {
  color: black;
  border-radius: 20%, 20%;
  background-color: rgba(255, 255, 255, 0.8); 
  font-family: 'Chakra Petch', sans-serif;
  padding-left: 7%;
  border: 2px solid white;
  padding-right: 7%;
  font-weight: 500;

}

.home-signup-button.home-main {
  font-size: 2.5rem;
  background-color: rgba(255, 255, 255); 
}

.home-signup-button.home-main:hover {
  background-color: #d1d1d1;
  pointer-events: auto;
}


.home-signup-button:hover {
  background-color: #ffffff;
  pointer-events: auto;
}

.header-home {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background-color: transparent;
  color: #fff;
  text-align: left;
  height: auto;
  z-index: 100;
}

.home-page-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-grow: 1;
  background-size: cover;
}

.mobile-header-nav {
  position: fixed;
  top: 15vh;
  right: 11.5vw;
  background-color: rgba(93, 93, 93, 0.5);
  width: 20%;
  border: 1px solid #ccc;
  z-index: 100;
  text-align: right;

}

.mobile-header-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-header-nav li {
  opacity: 0;
  display: flex;
  padding: 10px;
  border-bottom: none;
  text-decoration: none;
  text-align: right;
  justify-content: right;
}

.mobile-header-nav li a,
.mobile-header-nav li button {
  display: block;
  padding: 0;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: 650;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 1.5rem;
  margin: 0;
  background-color: rgba(0,0,0,0);
}




.scroll-down-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 24px;
}
  
.scroll-down-indicator span {
  display: inline-block;
  width: 40px; 
  height: 40px; 
  background-color: white;
  border-radius: 50%; 
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
}
  
.scroll-down-indicator span::before {
  content: '▼';
  color: #333;
  position: relative;
  top: 7%;
  left: 17%;
}
  
.header-nav .link-button:hover {
  background: none;
}

.hamburger-icon {
  font-family: 'Chakra Petch', sans-serif;
  background-color: rgb(27, 32, 54, 0.4);
  border: 1px solid #fff;
  font-size: 40px;
  cursor: pointer;
  padding: 1vh 2vh;
  right: 10vw;
  top: 5vh;
  position: fixed;
}

.home-title-alt .home-subheading-alt {
  color: #14491e;
  font-family: 'Chakra Petch', sans-serif;
}

.home-title-alt {
  margin-left: 2.5%;
  font-size: 3rem;
}

.image-container {
  position: relative;
  width: 100%; 
  height: auto; 
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to right, 
    rgb(27, 32, 54) 0%,
    rgb(27, 32, 54) 15%,
    rgba(0,0,0,0) 55%);
}



.home-text-overlay {
  position: absolute;
  height: 100vh;
  width: 100%;
}

.header-logo.home-logo {
  position: absolute;
  left: 8vw;
  top: 7vh;
}

.home-logo-img {
  border-radius: 10px;
}




.header-nav.home a, .link-button-create.home {
  color: #003c04;
  font-weight: 700;
}

.home-sub { 
  color: #20783c;
  font-family: 'Chakra Petch', sans-serif;
  font-weight: 750;
  font-size: 1.7rem;
}

.home-signup-button.alt-btn {
  background-color: rgb(42, 148, 63);
  color: white;
  border: 1px solid rgba(0,0,0,0.4);
}

.home-signup-button.alt-btn-learn {
  background-color: rgba(42, 93, 148, 0.784);
  color: white;
  border: 1px solid rgba(0,0,0,0.4);
}

.home-signup-button.alt-btn:hover {
  background-color: rgb(20, 69, 30);
}

.home-signup-button.alt-btn-learn:hover {
  background-color: rgba(13, 29, 45, 0.784);
}

.home-subheading-alt {
  text-align: center;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
}

.header-logo img {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scroll-down-arrow {
  position: absolute;
  bottom: 2vh; 
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent; /* Adjust size as necessary */
  border-right: 20px solid transparent; /* Adjust size as necessary */
  border-top: 20px solid #fff; /* Adjust color as necessary */
  animation: bounce 2s infinite;
  cursor: pointer;
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-20px);
  }
  60% {
    transform: translateX(-50%) translateY(-10px);
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.footer-home {
  display: flex;
  list-style: none; 
  flex-direction: row; 
  /* justify-content: space-around;  */
  width: 100%; 
  box-sizing: border-box;
  font-family: 'Chakra Petch', sans-serif;
}

.footer-logo, .footer-about, .footer-help {
  display: flex;
  flex-direction: column;
  align-items: left; 
}
.footer-about, .footer-help {
  margin-left: 10vw;
}


.footer-logo img {
  width: 100px;
  height: auto; 
  border-radius: 5px;
  margin-left: 0;
}

.footer-copyright {
  text-align: center;
  width: 100%;
  margin-top: 20px; 
  font-size: 0.75rem; 
}

.footer-copyright p {
  margin-left: 0;
  margin-bottom: 0;
}

.footer-about li, .footer-about a, .footer-help li, .footer-help a  {
  text-decoration: none;
  margin-left: 0;
}

@media (max-width: 768px) {
  .header-logo p {
    font-size: 0.75rem;
    white-space: nowrap;
    margin-left: 3vw;
    text-align: center;
  }
  .header-logo img {
    margin-left: 3vw;
    width: 19vw;
  }

  .text-center.text-white {
    position: absolute;
    top: 30%;
    width: 100%; 
    left: 0;
  }

  .header-logo {
    position: fixed;
  }

  .home-title {
    font-size: 4rem;
  }

  .home-title-alt {
    font-size: 3.5rem;
    color: white;
    width: 95%;
  }

  .hamburger-icon {
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    right: 7vw;
    top: 2vh;
    position: fixed;
    margin: 0;
    padding: 0;
  }

  .home-subheading {
    font-size: 1rem;
    font-weight: 600;
    padding: 2vh;
  }

  .home-subheading-alt {
    font-size: 1rem;
    font-weight: 600;
    padding: 2vh;
    width: 90%;
    color: white;
  }
  .home-signup-button {
    font-size: 1rem; 
  }

  .mobile-header-nav {
    position: fixed;
    top: 10vh;
    right: 7vw;
    background-color: rgba(93, 93, 93, 0.5);
    width: 40%;
    border: 1px solid #ccc;
    /* box-shadow: 0 8px 16px rgba(0,0,0,0.); */
    z-index: 100;
    text-align: right;
  
  }
  
  .mobile-header-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .mobile-header-nav li {
    opacity: 0;
    display: flex;
    padding: 10px;
    border-bottom: none;
    text-decoration: none;
    text-align: right;
    justify-content: right;
  }
  
  .mobile-header-nav li a,
  .mobile-header-nav li button {
    display: block;
    padding: 0;
    font-size: 1rem;
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 650;
    font-family: 'Chakra Petch', sans-serif;
    margin: 0;
    background-color: rgba(0,0,0,0);
  }
  
  .mobile-header-nav li:last-child a,
  .mobile-header-nav li:last-child button {
      border-bottom: none;
  }
}




.mobile-nav-container {
  position: relative;
}



.hamburger-icon:hover {
  background-color: rgb(27, 32, 54, 0.7);
}

.mobile-nav {
  position: relative;
}



@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

