.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-family: 'Chakra Petch', sans-serif;
}

.not-found-title {
    font-weight: 750;
    font-size: 6.5rem;
    color: white;
}

.not-found-text {
    font-weight: 750;
    font-size: 3rem;
    color: white;
}